<template>
  <b-container class="p-0">
    <!-- Terms and Conditions -->
    <b-row class="my-2">
      <b-col cols="12">
        <h3>Terms and Conditions</h3>
        <b-card class="mt-1 mb-1">
          <b-row>
            <b-col>
              CONTEST RULES<br>
              (the "Official Rules")<br>
              <br>
              Daily Survivor Pool<br>
              (the "Contest")<br>
              <br>
              Updated January 15th, 2023, to reflect the revised regular season schedule.<br>
              <br>
              ENTRY PERIOD & CONTEST SPONSORS.<br>
              <br>
              The Contest is brought to you by the following entities (collectively or individually, the "Contest Sponsors"): Daily Survivor Pool ("Rogers").<br>
              The Contest entry period (the "Entry Period") starts everyday at 8 a.m. MST and ends between 1-5 p.m. MST depending on the start time of the specific event. Please check at the start time for the stated close time.<br>
              <br>
              HOW TO ENTER AND PARTICIPATE.<br>
              <br>
              No purchase necessary to enter this Contest. Entry is subject to these Official Rules, including without limitation the conditions of entry set forth below.<br>
              <br>
              During the Entry Period, go to https://dailysurvivorpool.com (the "Contest Website") and follow instructions to: (i) complete the registration process, and (ii) join an existing pool by answering "Today's Question". Play the game as set out in section 3 of these Official Rules (the "Contest Game"). Participants may be eligible to win a prize in this Contest depending the number of questions the participant answers. A participant may not create more than one (1) Contest profile.<br>
              <br>
              ENTRY LIMIT: One (1) Contest Game play per person, per email address. By way of illustration, if two (2) or more otherwise eligible individuals share a single email address, only one (1) of them may enter the Contest; and, if an eligible individual has multiple email addresses, he or she may only enter the Contest once.<br>
              <br>
              Any attempt or suspected attempt to enter this Contest in a fashion not authorized by these Official Rules shall be deemed to be tampering and will void all of your entries. Entries that contain false information and/or are late, lost, stolen, falsified, illegible, damaged, misdirected, mutilated, garbled or incomplete, altered or otherwise irregular or entries that have been submitted using robotic, automated, programmed, or through illicit means, or that do not conform with or satisfy any or all of these Official Rules, as determined in the Contest Sponsors' absolute discretion, will be judged null and void and disqualified. Only entries received by the Contest Sponsors will be considered. Proof of entry transmission shall not constitute proof of receipt. Contest Sponsors reserve the right to refuse any entry in their absolute discretion. The sole determinant of time for valid online entry in this Contest will be the Contest website's server machine(s).<br>
              <br>
              Although this Contest may be communicated, promoted, or administered by means of any third party social media or social networking service or site (each, a "Third Party Service"), entrants acknowledge that: (i) this Contest is not sponsored, endorsed or administered by, or associated with, any Third Party Service; (ii) if entry into this Contest is by means of a Third Party Service, entrants must have a valid account with the applicable Third Party Service (and may be required to have a public (i.e. non-private) account in order to participate) and must comply with the applicable Third Party Service's terms and policies; and (iii) any questions, comments or complaints regarding this Contest should be directed to the Contest Sponsors and not to any Third Party Service. By participating in this Contest, you completely release any Third Party Service of all liability in relation to any injury, damage or loss that may occur, directly or indirectly, in whole or in part, from your participation.<br>
              <br>
              THE CONTEST GAME.<br>
              <br>
              The Contest is based on answering yes/no questions. If a participant answers the question wrong they are removed from the pool. The last participant in a pool wins the pool. In the event of a tie, 2 or more participants answer the question wrong and no one answered correctly, all the particpants still in the pool will be moved to the next day's question until a winner is determined.<br>
              <br>
              CONDITIONS OF ENTRY.<br>
              <br>
              By entering this Contest, you:<br>
              <br>
              i) agree to be bound by these Official Rules, including without limitation the eligibility requirements set forth below;<br>
              <br>
              ii) agree to be bound by the decisions of the Contest Sponsors and their representatives, or the Contest judges (if any), which decisions are final, binding and conclusive (without appeal) on all matters relating to the Contest;<br>
              <br>
              iii) represent and warrant that your entry, including any material comprising your entry (e.g. name, user name, profile picture, etc., as applicable) and any material submitted with your entry (e.g. photograph, video, written submission, or other form of submission, etc., as applicable) (collectively, the "Entry Material") (i) is original to you, and that you have all necessary rights in and to your Entry Material to enter the Contest, including, without limitation, the consent of any third parties whose personal information is included in your Entry Material, and (ii) does not contain, depict, include or involve content that is, or could reasonably be considered to be, inappropriate, unsuitable or offensive, as determined by Contest Sponsors in their sole discretion;<br>
              <br>
              iv) understand and agree that: (i) your entry (including any Entry Material) may not be returned to you upon submission to the Contest and may be refused as entry to this Contest, as determined by Rogers in its sole discretion; and (ii) Rogers may, in its absolute discretion, moderate and/or remove and/or edit any Entry Material, including to blur out any trademarks or to remove any copyrighted content or otherwise unsuitable content (as determined by Rogers);<br>
              <br>
              v) grant to Rogers a worldwide, perpetual, royalty-free, irrevocable, non-exclusive, sub-licensable and unlimited licence to use your entry, including the Entry Material, in any media and for any purpose related to the Contest (or any substantially similar contest), including without limitation the right to use, reproduce, modify, adapt, translate, alter, or create derivative works from, the entry and/or Entry Material, without notification, compensation or additional consideration to you; and<br>
              <br>
              vi) waive all claims of moral rights in your entry and/or Entry Material and in any use thereof in accordance with these Official Rules.<br>
              <br>
              ELIGIBILITY.<br>
              <br>
              This Contest is open to residents of Canada (excluding residents of Quebec) who have reached the age of majority in their province or territory of residence as of the date of entry.<br>
              <br>
              The following individuals are not eligible to enter the Contest:<br>
              <br>
              i) employees, officers, directors, agents, and representatives of: (1) the Contest Sponsors and their parent and affiliated companies, (2) the Contest prize supplier(s), (3) the Contest judges, if applicable, and (4) any and all other companies associated with the Contest, including without limitation the Contest administrator, SCAinteractive; and<br>
              <br>
              ii) a household member of any of the individuals listed in (i), above, whether or not related.<br>
              The Contest Sponsors shall have the right at any time to require proof of identity and/or eligibility to enter the Contest. Failure to provide such proof may result in disqualification. All personal and other information requested by and supplied for the purpose of the Contest must be truthful, complete, accurate and in no way misleading. The Contest Sponsors reserve the right, in their sole discretion, to disqualify any entrant should such an entrant at any stage supply untruthful, incomplete, inaccurate or misleading personal details and/or information.<br>
              <br>
              PRIZE DESCRIPTION.<br>
              <br>
              There is a total of one daily prize available to be won in connection with each pool, as more particularly set out below:<br>
              <br>
              One (1) eligible participant will win the first prize in the Grand Prize Period, consisting of twenty five dollars (CDN $25);<br>
              <br>
              All potential winners will be contacted through the information provided by the participant at the time of entry. <br>
              <br>
              The monetary prizes will be awarded by cheque in the winner's name. A cheque may take up to 6 weeks to be issued.<br>
              <br>
              ODDS OF WINNING.<br>
              <br>
              Odds of winning this Contest depend on the number of eligible participants and the number of questions answered by the participant while playing the Contest Game.<br>
              <br>
              GENERAL PRIZE CONDITIONS.<br>
              <br>
              The terms and conditions contained in this Section apply to any and all Contest prize(s) awarded pursuant to these Official Rules:<br>
              <br>
              Except with respect to the monetary prizes, no financial compensation will be made or required if actual prize value is lower than the total value quoted in these Official Rules.<br>
              <br>
              Prize must be accepted as awarded, without substitution, transfer, exchange or assignment, unless otherwise determined in the absolute discretion of the Contest Sponsors and/or prize supplier(s). Prize may not be exactly as advertised. Except as expressly warranted herein, prize is provided "as is" without further warranty of any kind.<br>
              <br>
              Any unused portion of the prize, once awarded, will be deemed forfeited by the applicable winner, and no financial compensation will be made or required in respect of such unused portion. Prize will not be replaced if lost, destroyed, mutilated or stolen.<br>
              <br>
              The Contest Sponsors and/or the prize supplier(s) reserve the right, in their absolute discretion, to cancel or substitute a prize or a component of a prize with a prize or a component of a prize (as applicable) of equal or greater value, including, without limitation, a monetary award, if the prize or prize component cannot be awarded by the Contest Sponsors and/or the prize supplier(s) for any reason.<br>
              <br>
              Once awarded, prize may not be resold or commercially traded in any manner, directly or indirectly, and Contest Sponsors reserve the right to ban or disqualify any entrant from any contest, including future contests, should it reasonably believe such entrant to have acted or attempted to act in contravention of the foregoing restriction.<br>
              <br>
              WINNER SELECTION / NOTIFICATION.<br>
              <br>
              The last player to answer a question correctly will be eligible to win one (1) Prize.<br>
              <br>
              A potential winner will be disqualified and required to forfeit any claim to the Contest prize if he or she cannot be reached within ten (10) days following the first attempt of contact, which contact shall be made by means of telephone and/or email, or if the terms set forth in these Official Rules are not adhered to.<br>
              <br>
              Prizes will be distributed and delivered within sixty (60) days after each prize winner has been successfully contacted and notified of his/her prize, provided the winner has complied with these Official Rules.<br>
              <br>
              Decisions and rulings of the Contest Sponsors and/or their representatives are final and binding without appeal in all matters related to this Contest and the awarding of a prize.<br>
              <br>
              To be declared a winner, a potential winner must correctly answer without assistance of any kind, whether mechanical or otherwise, a time-limited, mathematical skill-testing question; be in full compliance with these Official Rules; and, in the discretion of the Contest Sponsors, sign and return a release of liability and consent to publicity form (the "Release Form") within the time period specified in the Release Form, and any other documentation as may reasonably be required by the Contest Sponsors in their absolute discretion.<br>
              <br>
              A potential winner may be required to provide proof of identification to the Contest Sponsors when claiming a prize or otherwise in connection with this Contest to facilitate the Contest Sponsors' accurate identification of a Contest winner.<br>
              <br>
              If a potential winner does not fulfill the conditions set out in these Official Rules, or declines or forfeits a Contest prize, the Contest Sponsors reserve the right, in their absolute discretion, to cancel the Contest prize or to select another entrant from the remaining eligible entries pursuant to the process described above.<br>
              <br>
              RELEASE OF LIABILITY, INDEMNITY.<br>
              <br>
              By entering this Contest, you: (a) agree to remise, release and forever discharge the Contest Sponsors, their respective parent and affiliated companies, any and all other companies associated with the Contest (including prize suppliers and suppliers of materials or services related to the Contest), and all of their respective employees, directors, officers, shareholders, agents, representatives, successors and assigns (collectively, the "Releasees") from any and all actions, causes of action, suits, debts, dues, accounts, claims, damages or liability for any loss, harm, damages, costs or expenses, including, without limitation, costs or losses related to personal injuries, death, damage to, loss or destruction of property, and rights of publicity, personality, privacy and/or intellectual property (each, a "Claim") arising out of, or in any way related to, your participation in the Contest and/or the awarding, receipt, possession, use and/or misuse of any Contest prize (or any portion thereof), or any travel or activity that is related to the receipt or use of any Contest prize; and (b) agree to indemnify and hold harmless each of the Releasees from and against any and all Claims arising from (i) your breach of these Official Rules, including the breach of any representations or warranties contained herein, (ii) your participation in the Contest, (iii) your acceptance, possession, use and/or misuse of any Contest prize (or any portion thereof), if applicable, or (iv) the use of any Entry Material in accordance with the rights granted in these Official Rules.<br>
              <br>
              CONSENT TO PUBLICITY.<br>
              <br>
              By accepting a Contest prize, if applicable, you authorize the Contest Sponsors and each of their respective licensees, successors, assigns, agents, representatives and employees the right, licence and permission to record, photograph and/or otherwise capture or document you and/or your likeness, including without limitation your voice and any statements you may make regarding the Contest prize, by any available means, and to use any such recordings, photographs or documents, as well as your image and/or likeness appearing therein, and your biographical information, including your name, city and province/territory of residence, throughout the world and in perpetuity, in all manner and media, whether now known or hereafter devised, for advertising or promotional purposes relating to the Contest (or any substantially similar contest), without limitation and without compensation or additional consideration, notification, or permission of any kind, unless prohibited by law; and you waive any rights that you may have or that may otherwise exist in respect of any materials produced pursuant to the foregoing, including without limitation rights of inspection, approval, compensation, additional consideration or notification, and moral rights.<br>
              <br>
              LIMITATION OF LIABILITY.<br>
              <br>
              The Releasees are not responsible for: (a) stolen, late, incomplete, illegible, inaccurate, misdirected, lost, misrouted, scrambled, damaged, delayed, undelivered, mutilated, postage-due or garbled entries, transmissions, email or mail; (b) lost, interrupted or unavailable network, cable, satellite, server, Internet Service Provider, website, or other connections, including those through and/or by any website; (c) jumbled, scrambled, delayed, or misdirected transmissions or computer hardware or software malfunctions, failures or difficulties; (d) failures or malfunctions of phones, phone lines or telephone systems, any error, omission, interruption, defect or delay in transmission, processing, or communication; (e) non-delivered, misdirected, blocked, or delayed email notifications; (f) printing, typographical or other errors appearing within these Official Rules, in any Contest-related advertisements or other materials; or (g) any other errors, problems or difficulties of any kind, whether human, mechanical, electronic, network, computer, telephone, mail, typographical, printing or otherwise relating to or in connection with this Contest, including, without limitation, errors or difficulties which may occur in connection with the administration of the Contest, the processing of entries, the announcement of the prize or in any Contest-related materials, or the cancellation or postponement of any event. The Releasees are also not responsible for any incorrect or inaccurate information, including without limitation where caused by website users, tampering, hacking, or by any equipment or programming associated with or utilized in the Contest. The Releasees are not responsible for injury or damage to participants' or to any other person's computer related to or resulting from participation in this Contest or downloading materials from or use of any website.<br>
              <br>
              PRIVACY.<br>
              <br>
              By entering this Contest, each entrant consents to the collection, use, and disclosure of his/her personal information for the purposes and in the manner described herein. All information submitted by entrants is being collected by dailysurvivorpool and is subject to the dailysurvivorpool.com Privacy Policy, available at https://dailysurvivorpool.com/privacy-policy.<br>
              <br>
              Online entrants may be given the option to receive commercial emails and/or other communications from the Contest Sponsors or other parties; however, eligibility to participate in the Contest is not dependent upon an entrant's consent to receive any such emails and communications, and consenting to receiving such emails and communications will not impact an entrant's chances of winning. Entrants may at any time opt out of receiving Rogers informational or marketing communications by following the unsubscribe instructions provided at the bottom of any of these communications. Please consult the Rogers Media Privacy Policy referenced above for further information on how Rogers collects, uses, and discloses personal information. Any questions or concerns with respect to communications from Rogers may be addressed to the Rogers Chief Privacy Officer, whose contact particulars may be found in the Rogers Media Privacy Policy. Where you elect to receive informational or marketing communications from a party other than Rogers, you understand and agree that your personal information will be shared with such other party for the purpose of facilitating the sending of informational or marketing communications, and you further understand and agree that your personal information, as shared with the other party, will be subject to the other party's privacy policy and information handling standards and practices.<br>
              <br>
              In connection with prize fulfillment, Rogers may be required to provide your personal information to another party. By entering the Contest, you consent to such disclosure of your personal information in connection with the foregoing, and you understand and agree that, should your personal information be provided to another party, your information will be subject to that party's privacy policy and information handling standards and practices.<br>
              <br>
              GENERAL.<br>
              <br>
              LAWS AND RULES. This Contest will be run in accordance with these Official Rules, which shall be subject to amendment by Rogers without notice or liability to you. You must comply with these Official Rules and will be deemed to have received and understood these Official Rules by participating or attempting to participate in this Contest. The terms of this Contest, as set out in these Official Rules, are not subject to amendment or counter-offer, except as set out herein. This Contest is subject to all applicable federal, provincial and municipal laws and regulations. These Official Rules are governed exclusively by the laws of the province or territory in which you reside, and you submit to the exclusive jurisdiction of the courts of such province or territory. Rights and remedies may vary by province or territory.<br>
              <br>
              CANCEL AND AMEND. Rogers reserves the right to cancel, modify, or suspend this Contest and any prize or to amend these Official Rules at any time and in any way, without prior notice, for any reason whatsoever. Without limiting the foregoing, if for any reason the Contest is not capable of running as originally planned, for example as a result of tampering or infection by computer virus, bug, corruption, security breach or other cause beyond the reasonable control of the Contest Sponsors, Rogers reserves the right to cancel or suspend the Contest and/or conduct a random draw from all previously received eligible entries.<br>
              <br>
              CONDUCT. The Contest Sponsors reserve the right, in their absolute discretion, to disqualify without notice, and/or ban from this Contest and any future contests, any entrant that they find to be: violating these Official Rules; tampering or attempting to tamper with the entry process or the operation of the Contest or any Contest website; acting in an unsportsmanlike or disruptive manner, or with the intent to annoy, abuse, threaten or harass any other person; or attempting to undermine the legitimate operation of the Contest. Any attempt by an entrant or any other individual to undermine the legitimate operation of this Contest may be a violation of criminal and/or civil laws. Should any such attempt be made, the Contest Sponsors reserve the right to seek remedies and damages to the fullest extent permitted by law, including criminal prosecution.<br>
              <br>
              IDENTITY OF ONLINE ENTRANT. If a dispute arises regarding the identity of any online entrant, the applicable entry will be deemed to have been submitted by the authorized account holder of the account provided at the time of entry. An entrant may be required to provide proof that he or she is the authorized account holder of the account associated with a particular entry. The individual assigned by an Internet access provider, online service provider, or other organization responsible for assigning the applicable type of account is considered the authorized account holder. Whether or not an individual constitutes the authorized account holder in question will be determined by Rogers in its sole discretion; and, if the name of the authorized account holder does not accord with the full name provided at the time of entry, the applicable entry may be disqualified in Rogers' absolute discretion.
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BContainer, BRow, BCol,
  BCard,
} from 'bootstrap-vue'

import HomeBackground from '@/assets/images/backgrounds/home.png'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
  },
  data() {
    return {}
  },
  created() {
    document.body.style.backgroundImage = `url(${HomeBackground})`
  },
}
</script>

<style scoped>
.transparent {
  opacity: 0.2;
}
</style>
